const locations = {
  location_data: [
    {
      id: 1,
      location_status: "open",
      name: "Aliquippa",
      link: "/shop/aliquippa",
      title_text: "Welcome to Liberty Cannabis Aliquippa",
      address_line1: "2320 Sheffield Road",
      address_line2: "Aliquippa, PA 15001",
      phone: "724-203-3029",
      email: "Aliquippa@LibertyDispensaryPA.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: "/Aliquippa.webp",
      become_patient: "Join the PA cannabis community",
      tag: "Pennsylvania",
      latitude: 40.606309,
      longitude: -80.282621,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/5e9491bc8c76410099c8fa05.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4293/embed.js",
      city: "Aliquippa",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "15001",
      type: "Dispensary",
      address1: "2320 Sheffield Rd",
      address2: null,
      map_location: "http://bit.ly/38TMjgC",
    },
    {
      id: 3,
      location_status: "open",
      name: "Beach Center",
      link: "/shop/beach-center",
      title_text: "Welcome to Liberty Cannabis Beach Center",
      address_line1: "1115 W 190th Street",
      address_line2: "Gardena, CA 90248",
      phone: "310-821-4420",
      email: "Beach@LibertyCannabis.com",
      weekday_time: "Monday - Sunday 9 AM - 9 PM",
      weekend_time: "",
      weekday_open: "Open till 9PM",
      saturday_open: "Open till 9PM",
      sunday_open: "Open till 9PM",
      abbr: "CA",
      title_image: "/BeachCenter.webp",
      become_patient: "Join the Cali cannabis community",
      tag: "California",
      latitude: 33.859167,
      longitude: -118.295404,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/5e2b3a81ae2baf006ecae7a9.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4297/embed.js",
      city: "Gardena",
      state: "California",
      stateCode: "CA",
      postalCode: "90248",
      type: "Dispensary",
      address1: "1115 W. 190th Street",
      address2: null,
      map_location: "http://bit.ly/37Htw6r",
    },
    {
      id: 4,
      location_status: "open",
      name: "Bensalem",
      link: "/shop/bensalem",
      title_text: "Welcome to Liberty Cannabis Bensalem",
      address_line1: "4201 Neshaminy Boulevard",
      address_line2: "Bensalem, PA 19020",
      phone: "267-778-9222",
      email: "Bensalem@LibertyDispensaryPA.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: "/Bensalem.webp",
      become_patient: "Join the PA cannabis community",
      tag: "Pennsylvania",
      latitude: 40.136987,
      longitude: -74.950349,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/3TJq5LSGc9j76WKx4.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4296/embed.js",
      city: "Bensalem",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "19020",
      type: "Dispensary",
      address1: "4201 Neshaminy Boulevard",
      address2: null,
      map_location: "http://bit.ly/3sCSHRi",
    },
    {
      id: 6,
      location_status: "open",
      name: "Cranberry Township",
      link: "/shop/cranberry-township",
      title_text: "Welcome To Liberty Dispensary Cranberry Township",
      address_line1: "900 Commonwealth Drive, Suite 500",
      address_line2: "Cranberry Township, PA",
      phone: "878-208-7299",
      email: "CranberryTownship@LibertyDispensaryPA.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: "/home-title.webp",
      become_patient: "Join the PA medical marijuana community",
      tag: "Pennsylvania",
      latitude: 40.683367,
      longitude: -80.111687,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/60523818a6b5d500e0fb2e31.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4291/embed.js",
      city: "Cranberry Township",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "15086",
      type: "Dispensary",
      address1: "900 Commonwealth Drive, Suite 500",
      address2: null,
      map_location: "https://bit.ly/3wSspN5",
    },
    {
      id: 7,
      location_status: "open",
      name: "Detroit",
      link: "/shop/detroit",
      title_text: "Welcome to Liberty Cannabis Detroit",
      address_line1: "2540 Rosa Parks Boulevard",
      address_line2: "Detroit, MI 48216",
      phone: "313-450-1400",
      email: "Detroit@LibertyCannabis.com",
      weekday_time: "Monday, Wednesday - Saturday 11 AM - 7 PM",
      weekend_time: "Sunday 12 PM - 6 PM",
      weekday_open: "Open till 7PM",
      saturday_open: "Open till 7PM",
      sunday_open: "Open till 6PM",
      abbr: "MI",
      title_image: "/Detroit.webp",
      become_patient: "Join the MI cannabis community",
      tag: "Michigan",
      latitude: 42.334617,
      longitude: -83.073103,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/5ee0fdc52e11dd00d4634d20.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4286/embed.js",
      dutchieEmbedUrlREC: "https://api.iheartjane.com/v1/stores/5145/embed.js",
      city: "Detroit",
      state: "Michigan",
      stateCode: "MI",
      postalCode: "48216",
      type: "Dispensary",
      address1: "2540 Rosa Parks Boulevard",
      address2: null,
      map_location: "http://bit.ly/3bRiJdU",
    },
    {
      id: 8,
      location_status: "open",
      name: "Easthampton",
      link: "/shop/easthampton",
      title_text: "Welcome to Liberty Cannabis Easthampton",
      address_line1: "155 Northampton Street",
      address_line2: "Easthampton, MA 01027",
      phone: "413-893-9839",
      email: "Easthampton@LibertyCannabis.com",
      weekday_time: "Sunday - Wednesday 11 am - 6 pm",
      weekend_time: "Thursday - Saturday 11 am - 7 pm",
      weekday_open: "Open till 6 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 6 PM",
      abbr: "MA",
      title_image: "/Easthampton.webp",
      become_patient: "Join the Mass cannabis community",
      tag: "Massachusetts",
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/5f4fb0be7d3ecf00bcee72db.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4330/embed.js",
      dutchieEmbedUrlREC: "https://api.iheartjane.com/v1/stores/4284/embed.js",
      city: "Easthampton",
      state: "Massachusetts",
      stateCode: "MA",
      postalCode: "01027",
      type: "Dispensary",
      address1: "155 Northampton Street",
      address2: null,
      map_location: "http://bit.ly/3iqCCcT",
    },
    {
      id: 19,
      location_status: "open",
      name: "Pittsburgh",
      link: "/shop/pittsburgh",
      title_text: "Welcome to Liberty Cannabis Pittsburgh",
      address_line1: "3105 W Liberty Ave",
      address_line2: "Pittsburgh PA 15216",
      phone: "412-207-7065",
      email: "pittsburgh@libertydispensarypa.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: null,
      become_patient: "Join the PA cannabis community",
      tag: "Pennsylvania",
      latitude: 40.391026,
      longitude: -80.041119,
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4909/embed.js",
      // dutchieEmbedUrl: null,
      city: "Pittsburgh",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "15216",
      type: "Dispensary",
      address1: "3105 W Liberty Ave",
      address2: "Pittsburgh PA 15216",
      map_location: "https://bit.ly/3X0EdKd",
    },
    {
      id: 10,
      location_status: "open",
      name: "Madison Heights",
      link: "/shop/madison-heights",
      title_text: "Welcome to Liberty Cannabis Madison Heights",
      address_line1: "29600 Stephenson Hwy.",
      address_line2: "Madison Heights, MI 48071",
      phone: "248-268-2767",
      email: "MadisonHeights@LibertyCannabis.com",
      weekday_time: "Monday – Saturday 10 AM – 9 PM",
      weekend_time: "Sunday 11 AM – 8 PM",
      weekday_open: "Open till 9 PM",
      saturday_open: "Open till 9 PM",
      sunday_open: "Open till 8 PM",
      abbr: "MI",
      title_image: "/MadisonHeights.webp",
      become_patient: "Join the MI cannabis community",
      tag: "Michigan",
      latitude: 42.5107296, // 42.510884,
      longitude: -83.1190001, //-83.116779,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/60902baae8d75600dec4c693.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4285/embed.js",
      dutchieEmbedUrlREC: "https://api.iheartjane.com/v1/stores/4329/embed.js",
      city: "Madison Heights",
      state: "Michigan",
      stateCode: "MI",
      postalCode: "48071",
      type: "Dispensary",
      address1: "29600 Stephenson Hwy",
      address2: null,
      map_location: "https://bit.ly/3536NAO",
    },
    {
      id: 16,
      location_status: "open",
      name: "Maggie's - Baltimore",
      fullname: "Maggie's - Baltimore",
      link: "/shop/maggies",
      title_text: "Welcome to Maggie's - A Liberty Joint",
      address_line1: "3317 Keswick Rd",
      address_line2: "Baltimore, MD 21211",
      phone: "443-873-6974",
      email: "Maggies@libertycannabis.com",
      weekday_time: "Monday - Saturday 11 AM - 8 PM",
      weekend_time: "Sunday 11 AM - 8 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 8 PM",
      sunday_open: "Open till 8 PM",
      abbr: "MD",
      title_image: "/Maggies.webp",
      become_patient: "Join the MD cannabis community",
      tag: "Maryland",
      latitude: 39.32836,
      longitude: -76.62755,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/6v9wqPMPJA64Ldwpb.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4258/embed.js",
      city: "Baltimore",
      state: "Maryland",
      stateCode: "MD",
      postalCode: "21211",
      type: "Dispensary",
      address1: "3317 Keswick Rd",
      address2: null,
      map_location: "https://bit.ly/3wzcd5V",
    },
    {
      id: 11,
      location_status: "open",
      name: "Norristown",
      link: "shop/norristown",
      title_text: "Welcome to Liberty Cannabis Norristown",
      address_line1: "2030 W. Main Street, Unit #11",
      address_line2: "Norristown, PA 19403-6003",
      phone: "484-612-4520",
      email: "Norristown@LibertyDispensaryPA.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: "/Norristown.webp",
      become_patient: "Join the PA cannabis community",
      tag: "Pennsylvania",
      latitude: 40.133319,
      longitude: -75.380296,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/Tfr3uEztEfzqLT2xB.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4295/embed.js",
      city: "Norristown",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "19403",
      type: "Dispensary",
      address1: "2030 W. Main Street",
      address2: "Unit #11",
      map_location: "http://bit.ly/39OAibU",
    },
    {
      id: 12,
      location_status: "open",
      name: "Oxon Hill",
      link: "/shop/oxon-hill",
      title_text: "Welcome to Liberty Cannabis Oxon Hill",
      address_line1: "6144 Oxon Hill Road",
      address_line2: "Oxon Hill, MD 20745",
      phone: "240-766-4822",
      email: "oxonhill@libertycannabis.com",
      weekday_time: "Monday - Saturday 11 AM - 8 PM",
      weekend_time: "Sunday 11 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 8 PM",
      sunday_open: "Open till 5 PM",
      abbr: "MD",
      title_image: "/home-title.webp",
      become_patient: "Join the MD cannabis community",
      tag: "Maryland",
      latitude: 38.80411,
      longitude: -76.97787,
      // dutchieEmbedUrl: "",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4300/embed.js",
      city: "Oxon Hill",
      state: "Maryland",
      stateCode: "MD",
      postalCode: "20745",
      type: "Dispensary",
      address1: "6144 Oxon Hill Road",
      address2: "Oxon Hill, MD 20745",
      map_location: "https://bit.ly/3zGMLZM",
    },
    {
      id: 13,
      location_status: "open",
      name: "Philadelphia",
      link: "/shop/philadelphia",
      title_text: "Welcome to Liberty Cannabis Philadelphia",
      address_line1: "8900 Krewstown Road",
      address_line2: "Philadelphia, PA 19115-4517",
      phone: "267-686-2824",
      email: "Philadelphia@LibertyDispensaryPA.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "PA",
      title_image: "/Philadelphia.webp",
      become_patient: "Join the PA cannabis community",
      tag: "Pennsylvania",
      latitude: 40.08298,
      longitude: -75.049804,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/27eaLRYjHyMPZqigT.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4259/embed.js",
      city: "Philadelphia",
      state: "Pennsylvania",
      stateCode: "PA",
      postalCode: "19115",
      type: "Dispensary",
      address1: "8900 Krewstown Road",
      address2: null,
      map_location: "http://bit.ly/3bMwJ8F",
    },
    {
      id: 14,
      location_status: "open",
      name: "Rockville",
      link: "/shop/rockville",
      title_text: "Welcome to Liberty Cannabis Rockville",
      address_line1: "12001 Nebel Street",
      address_line2: "Rockville, MD 20852",
      phone: "301-603-2747",
      email: "Rockville@LibertyCannabis.com",
      weekday_time: "Monday - Saturday 10 AM - 7 PM",
      weekend_time: "Sunday 11 AM - 5 PM",
      weekday_open: "Open till 7 PM",
      saturday_open: "Open till 7 PM",
      sunday_open: "Open till 5 PM",
      abbr: "MD",
      title_image: "/Rockville.webp",
      become_patient: "Join the MD cannabis community",
      tag: "Maryland",
      latitude: 39.051296,
      longitude: -77.109327,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/6v9wqPMPJA64Ldwpb.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4254/embed.js",
      city: "Rockville",
      state: "Maryland",
      stateCode: "MD",
      postalCode: "20852",
      type: "Dispensary",
      address1: "12001 Nebel Street",
      address2: null,
      map_location: "http://bit.ly/3qsv2RR",
    },
    {
      id: 16,
      location_status: "open",
      name: "San Francisco",
      link: "/shop/san-francisco",
      title_text: "Welcome to Liberty Cannabis San Francisco",
      address_line1: "2222 Bush Street",
      address_line2: "San Francisco, CA 94115",
      phone: "415-923-8806",
      email: "SanFrancisco@LibertyCannabis.com",
      weekday_time: "Monday - Sunday 10 AM - 8 PM",
      weekend_time: "",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 8 PM",
      sunday_open: "Open till 8 PM",
      abbr: "CA",
      title_image: "/SanFrancisco.webp",
      become_patient: "Join the CA cannabis community",
      tag: "California",
      latitude: 37.78736,
      longitude: -122.43393,
      // dutchieEmbedUrl:"https://dutchie.com/api/v2/embedded-menu/5fc66101a63a7500b2688e7e.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4290/embed.js",
      city: "San Francisco",
      state: "California",
      stateCode: "CA",
      postalCode: "94115",
      address1: "2222 Bush Street",
      address2: null,
      type: "Dispensary",
      map_location: "http://bit.do/fMYu7",
    },
    {
      id: 17,
      location_status: "open",
      name: "Somerville",
      link: "/shop/somerville",
      title_text: "Welcome to Liberty Cannabis Somerville",
      address_line1: "304 Somerville Ave",
      address_line2: "Somerville, MA 02143",
      phone: "857-997-8207",
      email: "Somerville@LibertyCannabis.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 11 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 8 PM",
      sunday_open: "Open till 5 PM",
      abbr: "MA",
      title_image: "/Somerville.webp",
      become_patient: "Join the Mass cannabis community",
      tag: "Massachusetts",
      latitude: 42.380092,
      longitude: -71.097478,
      // dutchieEmbedUrl: "https://dutchie.com/api/v2/embedded-menu/y3wvjnoqRGzpM2DjS.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4301/embed.js",
      city: "Somerville",
      state: "Massachusetts",
      stateCode: "MA",
      postalCode: "02143",
      type: "Dispensary",
      address1: "304 Somerville Ave",
      address2: null,
      map_location: "http://bit.ly/3bT5w42",
    },
    {
      id: 18,
      location_status: "open",
      name: "Springfield",
      link: "/shop/springfield",
      title_text: "Welcome to Liberty Cannabis Springfield",
      address_line1: "1300 Boston Road",
      address_line2: "Springfield, MA 01119",
      phone: "413-750-9696",
      email: "Springfield@LibertyCannabis.com",
      weekday_time: "Monday - Saturday 10 AM - 8 PM",
      weekend_time: "Sunday 10 AM - 5 PM",
      weekday_open: "Open till 8 PM",
      saturday_open: "Open till 8 PM",
      sunday_open: "Open till 5 PM",
      abbr: "MA",
      title_image: "/Springfield.webp",
      become_patient: "Join the Mass cannabis community",
      tag: "Massachusetts",
      latitude: 42.141328,
      longitude: -72.500576,
      // dutchieEmbedUrl:
      //   "https://dutchie.com/api/v2/embedded-menu/5f98867881b29700c8e58d76.js",
      dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4283/embed.js",
      city: "Springfield",
      state: "Massachusetts",
      stateCode: "MA",
      postalCode: "01109",
      address1: "1300 Boston Road",
      address2: null,
      type: "Dispensary",
      map_location: "http://bit.do/fMYrq",
    },
    // {
    //   id: 20,
    //   location_status: "open",
    //   name: "Van Nuys",
    //   link: "/shop/van-nuys",
    //   title_text: "Welcome to Liberty Cannabis Van Nuys",
    //   address_line1: "15223 Burbank Boulevard",
    //   address_line2: "Van Nuys, CA 91411",
    //   phone: "240-880-2760",
    //   email: "VanNuys@LibertyCannabis.com",
    //   weekday_time: "Monday - Sunday 1 PM - 8 PM",
    //   weekend_time: "",
    //   weekday_open: "Open till 8 PM",
    //   saturday_open: "Open till 8 PM",
    //   sunday_open: "Open till 8 PM",
    //   abbr: "CA",
    //   title_image: "/VanNuys.webp",
    //   become_patient: "Join the Cali cannabis community",
    //   tag: "California",
    //   latitude: 34.1724046,
    //   longitude: -118.4670031,
    //   /// dutchieEmbedUrl:
    //   //   "https://dutchie.com/api/v2/embedded-menu/605237ee8353f000c0435dcc.js",
    //   dutchieEmbedUrl: "https://api.iheartjane.com/v1/stores/4298/embed.js",
    //   city: "Van Nuys",
    //   state: "California",
    //   stateCode: "CA",
    //   postalCode: "91411",
    //   address1: "15223 Burbank Boulevard",
    //   address2: null,
    //   type: "Dispensary",
    //   map_location: "http://bit.do/fPLPM",
    // },
    ,
  ],
}

module.exports = locations
